import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import IGetBestOfTheYear from '../../api/zawody/model/GetBestOfTheYearResult';

import { renderLista, renderNaglowek } from '../shared/utils';

const Top10Mlodzik: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [wynikiData, setwynikiData] = useState<IGetBestOfTheYear[]>();

    const getWyniki = () => {
        setLoaderVisible(true);
        statystykiClient()
            .getBestResultsOfYearMlodzik()
            .then((result: IGetBestOfTheYear[]) => {
                setwynikiData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        getWyniki();
    }, []);

    const renderLista1 = () => {
        return renderLista(wynikiData);
    };

    return (
        <>
            {renderNaglowek('Top 10 wyników z tego roku w kategorii Młodzik')}
            <Container className="Panel">
                <Row xs={1} sm={1} md={2} lg={3}>
                    {renderLista1()}
                </Row>
            </Container>

            <ToastContainer />
        </>
    );
};

export default Top10Mlodzik;
