const dev = {
    apiGateway: {
        URL: 'https://amigo.t-it.pl/',
    },
    iframeUrl: 'http://localhost:8088/',
};

const prod = {
    apiGateway: {
        URL: 'https://amigo.t-it.pl/',
    },
    iframeUrl: '/csounb/Aplikacja/',
};

//const typ1 = process.env.NODE_ENV; // jak npm start to 'development', npm test to 'test', npm run build to 'production'
const typ2 = process.env.REACT_APP_STAGE; //to co przekazane w package.json => scripts
const config = typ2 === 'prod' ? prod : dev;

export default {
    //ouEditUrl: '/m_siecsprzedazy2/protected/editjednostkaorganizacyjna.asp?jednorg_id=',
    //ouEditUrl: '/csounb/aplikacjaReactGui/organizationUnit/',
    ouEditUrl: 'http://localhost:3000/organizationUnit/',
    ...config,
};
