import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import IGetBestOfTheYear from '../../api/zawody/model/GetBestOfTheYearResult';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IGetStatNumberResult from '../../api/zawody/model/GetStatNumberResult';
import Card from 'react-bootstrap/Card';
import dateFormat from 'dateformat';
import koko from '../koko.png';

const DashBoard: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [wynikiData, setwynikiData] = useState<IGetBestOfTheYear[]>();
    const [wynikiIloscData, setwynikiIloscData] = useState<IGetStatNumberResult[]>([]);
    const [zawodnicyData, setzawodnicyData] = useState<IGetStatNumberResult[]>([]);
    const [zawodyData, setzawodyData] = useState<IGetStatNumberResult[]>([]);

    const getWyniki = () => {
        const id = 1;
        setLoaderVisible(true);
        statystykiClient()
            .getBestResultsOfYear()
            .then((result: IGetBestOfTheYear[]) => {
                setwynikiData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    const getStatsWyniki = () => {
        const id = 1;
        setLoaderVisible(true);
        statystykiClient()
            .getStatsWyniki()
            .then((result: IGetStatNumberResult[]) => {
                setwynikiIloscData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    const getStatsZawody = () => {
        const id = 1;
        setLoaderVisible(true);
        statystykiClient()
            .getStatsZawody()
            .then((result: IGetStatNumberResult[]) => {
                setzawodyData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    const getStatsZawodnicy = () => {
        const id = 1;
        setLoaderVisible(true);
        statystykiClient()
            .getStatsZawodnicy()
            .then((result: IGetStatNumberResult[]) => {
                setzawodnicyData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        getWyniki();
        getStatsZawodnicy();
        getStatsZawody();
        getStatsWyniki();
    }, []);

    function zawodyLink(zawodyId: string) {
        return `/zawody/${zawodyId}/`;
    }
    function userLink(userId: string) {
        return `/zawodnik/${userId}/`;
    }

    const renderLista = () => {
        if (typeof wynikiData !== 'undefined') {
            const group1 = wynikiData.reduce((r: any, a: IGetBestOfTheYear) => {
                r[a.konkurencja] = [...(r[a.konkurencja] || []), a];
                return r;
            }, []);

            return Object.keys(group1).map(key => {
                return (
                    <Col key={key}>
                        <div className="konkurencjaDiv">{key}</div>
                        <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                            <thead>
                                <tr>
                                    <th>Wynik</th>
                                    <th>Nazwisko</th>
                                    <th>Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group1[key].map((item: IGetBestOfTheYear, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{item.suma}</td>

                                            <td className="text-center">
                                                <Link to={userLink(item.zawodnik_id)}>{item.nazwisko}</Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to={zawodyLink(item.zawody_id)}>{dateFormat(item.data, 'dd-mm-yyyy')}</Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                );
            });
        }
    };

    const zawodnicyDataOne = zawodnicyData[0];
    const zawodyDataOne = zawodyData[0];
    const wynikiDataOne = wynikiIloscData[0];

    return (
        <>
            <Container className="Panel">
                <Row>
                    <Col>
                        <Card className="text-center">
                            <Card.Header>Liczba zawodników w systemie</Card.Header>
                            <Card.Body>
                                <Card.Title>{zawodnicyDataOne?.ilosc}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-center">
                            <Card.Header>Liczba zawodów w systemie</Card.Header>
                            <Card.Body>
                                <Card.Title>{zawodyDataOne?.ilosc}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-center">
                            <Card.Header>Liczba wyników w systemie</Card.Header>
                            <Card.Body>
                                <Card.Title>{wynikiDataOne?.ilosc}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col></Col>
                </Row>
            </Container>

            {/* <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Special title treatment</h5>
                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            <a href="#" class="btn btn-primary">
                                Go somewhere
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Special title treatment</h5>
                            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            <a href="#" class="btn btn-primary">
                                Go somewhere
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}

            <Container className="Panel">
                <div className="DataZawodow">
                    Jeśli masz jakieś pytania, propozycje, brakuje jakichś zawodów, wnioski pisz na mejla <img className="koko_img" src={koko} />
                </div>
                <div className="mt-2 DataZawodow">Jeśli chcesz mieć łatwiejszy dostęp do wyników zawodników, to pobierz i zainstaluj wtyczkę do przeglądarki (Chrome/Opera) - na stronach siusa, nazwisko zmieni się w link do historii zawodnika i jego wszystkch wyników.</div>

                <a className="mt-1 btn btn-success btn-sm active bialyKurwa " href="https://chromewebstore.google.com/detail/t-it-stats-sius-extension/ddkhogldkdllopflgiolhilaaacidgod">
                    Pobierz wtyczkę do Chrome/Opera
                </a>
                <div className="NazwaZawodow">Top 10 wyników z tego roku</div>

                <Row xs={1} sm={1} md={2} lg={3}>
                    {renderLista()}
                </Row>
            </Container>
            <ToastContainer />
        </>
    );
};

export default DashBoard;
