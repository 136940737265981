import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import IGetBestOfTheYear from '../../api/zawody/model/GetBestOfTheYearResult';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IGetStatNumberResult from '../../api/zawody/model/GetStatNumberResult';
import IGetZawodnicyPerKlubResult from '../../api/zawody/model/GetZawodnicyPerKlubResult';
import { renderNaglowek } from '../shared/utils';

const Liczby: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [zawodnicyPerKlubData, setzawodnicyPerKlubData] = useState<IGetZawodnicyPerKlubResult[]>();

    const getStatsZawodnicyPerKlub = () => {
        const id = 1;
        setLoaderVisible(true);
        statystykiClient()
            .getStatsZawodnicyPerKlub()
            .then((result: IGetZawodnicyPerKlubResult[]) => {
                setzawodnicyPerKlubData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        getStatsZawodnicyPerKlub();
    }, []);

    function klubLink(klubId: string) {
        return `/klub/${klubId}/`;
    }

    const renderLista = () => {
        if (typeof zawodnicyPerKlubData !== 'undefined') {
            return (
                <div>
                    <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                        <thead>
                            <tr>
                                <th>Klub</th>
                                <th>Ilosc zawodników</th>
                            </tr>
                        </thead>
                        <tbody>
                            {zawodnicyPerKlubData.map((item: IGetZawodnicyPerKlubResult, index: any) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Link to={klubLink(item.klub)}>{item.klub}</Link>
                                        </td>
                                        <td>{item.ilosc}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            );
        }
    };

    return (
        <>
            {renderNaglowek('Liczba zawodników w klubie którzy wystartowali na zawodach issf')}

            <Container className="Panel">
                <Row>
                    <Col></Col>
                    <Col>{renderLista()}</Col>
                    <Col></Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    );
};

export default Liczby;
