import * as React from 'react';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IZawodyModel } from '../../api/zawody/model/ZawodyModel';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import './../ou/OU.scss';
import { Link } from 'react-router-dom';

interface IOUListState {
    isBusy: boolean;
    zawody: IZawodyModel[];
    loading: boolean;
}

class ZawodyEditList extends React.Component<any, IOUListState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isBusy: false,
            zawody: [],
            loading: false,
        };

        this.handleGridClickEdit = this.handleGridClickEdit.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData(null, null);
    }

    handleGridClickEdit(id: any) {
        const a = document.createElement('a');
        const editUrl = '/zawodydetale/';

        a.href = editUrl + id;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    fetchData(state: any, instance: any) {
        this.setState({ loading: true });
        this.setState({ isBusy: true });

        statystykiClient()
            .getAllZawody()
            .then((result: IZawodyModel[]) => {
                this.setState({ zawody: result });
                this.setState({ loading: false });
                this.setState({ isBusy: false });
            })
            .catch(() => {
                this.setState({ loading: false });
                this.setState({ isBusy: false });
            });
    }

    public render() {
        const result = this.state.zawody;
        const loading = this.state.loading;
        const loaderStyles = {
            opacity: this.state.isBusy ? '0.6' : '0',
            height: this.state.isBusy ? '100%' : '0',
            zIndex: 999999999,
        };

        return (
            <div>
                <div className="busy_loader" style={loaderStyles}>
                    <Loader type="Grid" />
                </div>
                <Container className="Panel">
                    <Row>
                        <Col>
                            <Link to="/zawodydetale/new" className="btn btn-primary">
                                Dodaj
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="spacer"></div>
                            <ReactTable
                                columns={[
                                    {
                                        Header: 'Id',
                                        id: 'Id',
                                        accessor: d => d.id,
                                        show: false,
                                    },
                                    {
                                        Header: 'Nazwa',
                                        accessor: 'nazwa',
                                        width: 250,
                                    },
                                    {
                                        Header: 'Miejsce',
                                        accessor: 'miejsce',
                                    },
                                    {
                                        Header: 'Data',
                                        accessor: 'data',
                                        Cell: ({ row, original }) => {
                                            return String(original.data).substring(0, 10);
                                        },
                                    },

                                    {
                                        Header: 'Ranga',
                                        accessor: 'ranga',
                                    },
                                    {
                                        Header: '',
                                        accessor: 'id',
                                        Cell: ({ value }) => <Button onClick={() => this.handleGridClickEdit(value)}>EDYTUJ</Button>,
                                        width: 120,
                                        sortable: false,
                                    },
                                ]}
                                data={result}
                                loading={loading} // Display the loading overlay when we need it
                                showPageJump={false}
                                previousText="Poprzednia"
                                nextText="Następna"
                                loadingText="Ładowanie..."
                                noDataText="Brak danych"
                                pageText="Strona"
                                ofText="z"
                                rowsText="wierszy"
                                defaultPageSize={10}
                                className="-striped -highlight"
                                filterable={true}
                                resizable={true}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default ZawodyEditList;
