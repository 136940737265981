import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import { renderNaglowek } from '../shared/utils';

const Narzedzia: React.FC = () => {
    return (
        <>
            {renderNaglowek('Narzędzia do użytku w treningu')}

            <Container className="d-grid gap-2">
                <Row>
                    <Col></Col>
                    <Col>
                        <div className="d-grid gap-2">
                            <a className="btn btn-success btn-lg btn-block bialyKurwa" href="http://strzelanko.t-it.pl/" target="_blank">
                                Czasoklikacz
                            </a>

                            <a className="btn btn-success btn-lg btn-block bialyKurwa" href="http://strzelanko.t-it.pl/potwierdzacz.html" target="_blank">
                                Potwierdzacz
                            </a>

                            <a className="btn btn-success btn-lg btn-block bialyKurwa" href="http://strzelanko.t-it.pl/wysokosciolicz.html" target="_blank">
                                Kalkulator wysokości zawieszenia pomniejszonej tarczy
                            </a>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    );
};

export default Narzedzia;
