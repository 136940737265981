import React, { ChangeEventHandler, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import TextInput from './../shared/controls/TextInput';
//import SelectInput from './../shared/controls/SelectInput';
//import { Option } from './../shared/controls/models/common';
//import DatePicker from './../shared/controls/DatePicker';
import { IZawodyModel } from '../../api/zawody/model/ZawodyModel';
import Loader from 'react-loader-spinner';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
//import IGetZawodyResponse from '../../api/zawody/model/ZawodyResponse';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
//import ISaveZawodyResponse from '../../api/zawody/model/SaveZawodyResponse';
//import { IWynikSaveModel } from '../../api/zawody/model/WynikSaveModel';
//import ISaveWynikiResponse from '../../api/zawody/model/SaveWynikResponse';
import IGetWynikiResponse from '../../api/zawody/model/GetWynikiResponse';
//import IGetSredniaResponse from '../../api/zawody/model/GetSredniaResponse';
import { Table } from 'react-bootstrap';
import { match } from 'react-router';
//import IZawodnikModel from '../../api/zawody/model/ZawodnikModel';
import { Link } from 'react-router-dom';
import IWynikModel from '../../api/zawody/model/WynikModel';
import dateFormat from 'dateformat';
import { renderNaglowek } from '../shared/utils';
import { Avatar } from '@mui/material';

interface IDetailParams {
    id: string;
}

interface IDetailsProps {
    match?: match<IDetailParams>;
}

const Zawody: React.FC<IDetailsProps> = ({ match }: IDetailsProps) => {
    const [loaderVisible, setLoaderVisible] = useState(false);

    // const [wynikiData, setwynikiData] = useState<IGetWynikiResponse[]>();
    const [zawodyData, setZawodyData] = useState<IZawodyModel>({
        nazwa: '',
        miejsce: '',
        data: '',
        ranga: '',
        id: '',
        wyniki: [],
        komunikat: '',
    });

    // const getWyniki = () => {
    //     const id = match?.params.id === undefined ? 0 : Number(match?.params.id);
    //     setLoaderVisible(true);
    //     statystykiClient()
    //         .getWynikiByZawody(id)
    //         .then((result: IGetWynikiResponse[]) => {
    //             setwynikiData(result);
    //             setLoaderVisible(false);
    //         })
    //         .catch(function(error) {
    //             console.log(error);
    //             setLoaderVisible(false);
    //         });
    // };

    useEffect(() => {
        setLoaderVisible(true);
        const id = match?.params.id === undefined ? 0 : Number(match?.params.id);
        //getWyniki();
        statystykiClient()
            .getZawody(id)
            .then((result: IZawodyModel) => {
                if (result == null) {
                    toast.error('Nie znaleziono zawodów', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else setZawodyData(result);

                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    }, []);

    const loaderStyles = () => {
        return {
            opacity: loaderVisible ? '0.6' : '0',
            height: loaderVisible ? '100%' : '0',
            zIndex: 999999999,
        };
    };
    function userLink(userId: string) {
        return `/zawodnik/${userId}/`;
    }
    function klubLink(klubId: string) {
        return `/klub/${klubId}/`;
    }

    const renderLista = () => {
        if (typeof zawodyData.wyniki !== 'undefined') {
            const group1 = zawodyData.wyniki?.reduce((r: any, a: IWynikModel) => {
                r[a.konkurencja.nazwa + ' ' + a.kategoriaWiekowa.nazwa + ' ' + a.plec.nazwa] = [...(r[a.konkurencja.nazwa + ' ' + a.kategoriaWiekowa.nazwa + ' ' + a.plec.nazwa] || []), a];
                return r;
            }, []);

            return Object.keys(group1).map(key => {
                return (
                    <div key={key}>
                        <div className="konkurencjaDiv">{key}</div>
                        <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                            <thead>
                                <tr>
                                    <th className="zawodyWynikiTableMiejsce">M-ce</th>
                                    <th className="zawodyWynikiTableNazwisko">Nazwisko</th>
                                    <th className="zawodyWynikiTableImie">Klub</th>
                                    <th className="zawodyWynikiTableseria">1</th>
                                    <th className="zawodyWynikiTableseria">2</th>
                                    <th className="zawodyWynikiTableseria">3</th>
                                    <th className="zawodyWynikiTableseria">4</th>
                                    <th className="zawodyWynikiTableseria">5</th>
                                    <th className="zawodyWynikiTableseria">6</th>
                                    <th className="zawodyWynikiTableseria">Suma</th>
                                    <th className="zawodyWynikiTableseria">10x</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group1[key].map((item: IGetWynikiResponse, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{item.miejsce}</td>
                                            <td>
                                                <div className="tablecellCenter">
                                                    <Avatar src={item.zawodnik.img ? 'https://wyniki.t-it.pl/static/images/avatars/' + item.zawodnik.img : '/user.png'} sx={{ width: 50, height: 50 }} />
                                                    <Link to={userLink(item.zawodnik.id)}>
                                                        {item.zawodnik.nazwisko} {item.zawodnik.imie}
                                                    </Link>
                                                </div>
                                            </td>
                                            <td className="vertical-center">
                                                <Link to={klubLink(item.zawodnik.klub)}>{item.zawodnik.klub}</Link>
                                            </td>
                                            <td className="text-center vertical-center">{item.seria1}</td>
                                            <td className="text-center vertical-center">{item.seria2}</td>
                                            <td className="text-center vertical-center">{item.seria3}</td>
                                            <td className="text-center vertical-center">{item.seria4}</td>
                                            <td className="text-center vertical-center">{item.seria5}</td>
                                            <td className="text-center vertical-center">{item.seria6}</td>
                                            <td className="text-center vertical-center">{+item.seria1 + +item.seria2 + +item.seria3 + +item.seria4 + +item.seria5 + +item.seria6}</td>
                                            <td className="text-center vertical-center">{item.centralne}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                );
            });
        }
    };

    return (
        <>
            <div className="busy_loader" style={loaderStyles()}>
                <Loader type="Grid" visible={loaderVisible} />
            </div>
            {renderNaglowek('Zawody w systemie')}
            <Container className="Panel">
                <div className="NazwaZawodow">{zawodyData?.nazwa}</div>
                <div className="MiejsceZawodow">Miejsce: {zawodyData?.miejsce}</div>
                <div className="DataZawodow">Data: {dateFormat(zawodyData?.data, 'dd-mm-yyyy')}</div>
                <div className="KomunikatZawodow">
                    Komunikat:{' '}
                    {zawodyData?.komunikat ? (
                        <a href={zawodyData?.komunikat} target="_blank" rel=" noopener noreferrer">
                            Pobierz
                        </a>
                    ) : (
                        'Brak'
                    )}
                </div>

                <Row>
                    <Col>{renderLista()}</Col>
                </Row>
            </Container>

            <ToastContainer />
        </>
    );
};

export default Zawody;
