import axios, { AxiosInstance } from 'axios';
import { getBaseApiUrl } from '.';

export default class BaseClient {
    public request(): AxiosInstance {
        return axios.create({
            baseURL: getBaseApiUrl(),
        });
    }
}
