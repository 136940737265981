import React, { ChangeEventHandler, useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';

import { GALLERY_CONSTS, ProGallery, Container as ImageContainer, Item, EventsListener } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';
import { CloseButton } from 'react-bootstrap';

interface IDetailsProps {
    items: Item[];
    //options={options}
    container: ImageContainer;
    //eventsListener?: EventsListener;
}

const GALLERY_EVENTS = GALLERY_CONSTS.events;
const styles: { [key: string]: React.CSSProperties } = {
    gallery: {},
    fullscreen: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        background: 'white',
        opacity: 0,
        transition: 'opacity 2s ease',
        visibility: 'hidden',
    },
    shown: {
        visibility: 'visible',
        opacity: 1,
    },
    close: {
        boxSizing: 'content-box',
        zIndex: 10,
        padding: 10,
        position: 'fixed',
        right: 20,
        top: 20,
        background: 'rgba(255,255,255,0.8)',
        borderRadius: 4,
        width: 25,
        height: 25,
        fill: 'black',
        cursor: 'pointer',
    },
};

const StatGallery: React.FC<IDetailsProps> = ({ items, container }: IDetailsProps) => {
    // useEffect(() => {}, []);
    const [fullscreenIdx, setfullscreenIdx] = useState<number>(-1);
    // The eventsListener will notify you anytime something has happened in the gallery.
    const eventsListener = (eventName: any, eventData: any) => {
        switch (eventName) {
            case GALLERY_EVENTS.ITEM_ACTION_TRIGGERED:
                setfullscreenIdx(eventData.idx);
                break;
            default:
                console.log({ eventName, eventData });
                break;
        }
    };

    const options = {
        galleryLayout: -1,
        scrollDirection: 1,
        hoveringBehaviour: 'NEVER_SHOW',
    };

    const container1 = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    //galeria ###################################################

    return (
        <>
            <section style={{ ...styles.gallery, display: fullscreenIdx < 0 ? 'block' : 'none' }}>
                <ProGallery items={items} container={container} options={options} key={`pro-gallery`} id={`pro-gallery`} eventsListener={eventsListener} />
            </section>

            {fullscreenIdx < 0 ? null : (
                <section style={{ ...styles.fullscreen, ...(fullscreenIdx >= 0 && styles.shown) }}>
                    <CloseButton className="dupalekClose" style={styles.close} onClick={() => setfullscreenIdx(-1)} />
                    <ProGallery
                        items={items}
                        key={`pro-fullscreen`}
                        id={`pro-fullscreen`}
                        activeIndex={fullscreenIdx}
                        container={container1}
                        options={{
                            ...options,
                            galleryLayout: 5,
                            textBoxHeight: 80,
                            slideAnimation: 'SCROLL',
                            cubeType: 'fit',
                            scrollSnap: true,
                            showArrows: true,
                        }}
                    />
                </section>
            )}
        </>
    );
};

export default StatGallery;
