import { matchSorter } from 'match-sorter';
import React, { ChangeEventHandler, useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Loader from 'react-loader-spinner';
import ReactTable from 'react-table-6';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import IAllZawodnikModel from '../../api/zawody/model/AllZawodnikModel';
import { Link } from 'react-router-dom';
import TextInput from '../shared/controls/TextInput';
import GetZawodnicyByNazwiskoRequest from '../../api/zawody/model/GetZawodnicyByNazwiskoRequest';
import { Avatar } from '@mui/material';
import { klubLink, userLink } from '../shared/utils';

const Wyszukaj: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [zawodnicyData, setZawodnicyData] = useState<IAllZawodnikModel[]>([]);

    const [searchTxt, setsearchTxt] = useState('');

    const getZawodnicy = () => {
        setLoaderVisible(true);
        statystykiClient()
            .getAllZawodnicy()
            .then((result: IAllZawodnikModel[]) => {
                setZawodnicyData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                //  console.log(error);
                setLoaderVisible(false);
            });
    };
    const getZawodnicyByName = () => {
        //create request
        const sercz: GetZawodnicyByNazwiskoRequest = {
            searchVal: searchTxt,
        };

        setLoaderVisible(true);
        statystykiClient()
            .getZawodnicyBySearch(sercz)
            .then((result: IAllZawodnikModel[]) => {
                setZawodnicyData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                //  console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        // getZawodnicy();
    }, []);

    const loaderStyles = () => {
        return {
            opacity: loaderVisible ? '0.6' : '0',
            height: loaderVisible ? '100%' : '0',
            zIndex: 999999999,
        };
    };

    // return current date in format YYYY-MM-DD HH:MM:SS
    const getCurrentDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        let month: string | number = date.getMonth() + 1;
        let day: string | number = date.getDate();
        let hours: string | number = date.getHours();
        let minutes: string | number = date.getMinutes();
        let seconds: string | number = date.getSeconds();

        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;

        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    };

    const szukaj = () => {
        if (searchTxt.length < 3) {
            toast.error('Wpisz conajmniej 3 litery.');
            return;
        }
        getZawodnicyByName();
    };

    const onchange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value, checked, type } = e.target;
        if (name === 'searchTxt') {
            //check for letters only and polish letters
            const regex = /[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/;
            if (regex.test(value)) {
                toast.error('Tylko litery');
                return;
            }

            setsearchTxt(value);
        }
    };

    const onkeydown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') szukaj();
    };

    //create function to display zawodnicy data like card with avatar and name and club and wojewodztwo and rocznik and button for details

    const zawodnicyCard = (zawodnik: IAllZawodnikModel) => {
        return (
            <div className="card searchCard">
                <Container>
                    <Row>
                        <Col className="zawodnikAvatar">
                            <Avatar src={zawodnik.img ? 'https://wyniki.t-it.pl/static/images/avatars/' + zawodnik.img : '/user.png'} sx={{ width: 1, height: 1 }} />
                        </Col>
                        <Col>
                            <h4 className="card-title">
                                <b>{zawodnik.nazwisko}</b>
                            </h4>
                            <h4 className="card-title">
                                <b>{zawodnik.imie}</b>
                            </h4>
                            <div className="ZawodnikKlub">
                                Klub:
                                <Link to={klubLink(zawodnik.klub)}>
                                    {zawodnik.klub} {zawodnik.miasto}
                                </Link>
                            </div>
                            <div>Rok urodzenia: {zawodnik.rocznik}</div>
                            <div>Województwo: {zawodnik.wojewodztwo}</div>
                            <Link className="btn btn-primary" to={userLink(zawodnik.zawodnik_id)}>
                                Szczegóły
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
    const zawodnicyCards = () => {
        return zawodnicyData.map((zawodnik: IAllZawodnikModel) => zawodnicyCard(zawodnik));
    };

    return (
        <>
            <div className="busy_loader" style={loaderStyles()}>
                <Loader type="Grid" visible={loaderVisible} />
            </div>
            <Container className="searchBlock">
                <Row>
                    <Col className="d-flex justify-content-center">
                        <h3>Wyszukaj zawodnika</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="input-group d-flex justify-content-center">
                        <TextInput name="searchTxt" placeholder="Nazwisko" value={searchTxt} error="" onChange={onchange} onkeydown={onkeydown} />

                        <Button onClick={szukaj}>Wyszukaj</Button>
                    </Col>
                </Row>
            </Container>
            <Container className="resultsBlock">
                <Row>
                    {zawodnicyCards().map((card, index) => (
                        <Col key={index}>{card}</Col>
                    ))}
                </Row>
            </Container>

            <ToastContainer />
        </>
    );
};

export default Wyszukaj;
