import React from 'react';
//import DatePicker, { registerLocale } from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
import './DatePicker.scss';
import { format, parse } from 'date-fns';
import { MobileDatePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { plPL } from '@mui/x-date-pickers/locales';

//registerLocale('pl', pl);
type Props = {
    name: string;
    value: string;
    onChange: (value: string, name: string) => void;
    error: string | null;
    textAbove?: string;
};

const SelectInput = (props: Props) => {
    function handleOnChange(date: Date | null) {
        if (date != null) props.onChange(format(date, 'yyyy-MM-dd'), props.name);
    }

    let wrapperClass = 'form-group';
    if (props.error && props.error.length > 0) {
        wrapperClass += ' has-error';
    }

    const getDateValue = (stringVal: string): Date | null => {
        if (typeof stringVal != 'undefined' && stringVal)
            if (stringVal.length > 10) return parse(stringVal.substring(0, 10), 'yyyy-MM-dd', new Date());
            else return parse(stringVal, 'yyyy-MM-dd', new Date());
        else return null;
    };
    return (
        <div className={wrapperClass}>
            <div style={{ width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl} localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker label={props.textAbove} format="dd-MM-yyyy" value={getDateValue(props.value)} onChange={handleOnChange} minDate={new Date(2019, 1, 1)} slotProps={{ textField: { size: 'small' } }} />
                </LocalizationProvider>
                {props.error && <div className="alert alert-danger"> {props.error} </div>}
            </div>
        </div>
    );
};

export default SelectInput;
