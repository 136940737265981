import React from 'react';
import { Option } from './models/common';
type Props = {
    name: string;
    options: Option[];
    value: string;
    onChange: (value: string, name: string) => void;
    error?: string | null;
};

const SelectInput = (props: Props) => {
    function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const { selectedIndex } = e.currentTarget;
        const selectedOption = props.options[selectedIndex];
        props.onChange(selectedOption.Value, e.currentTarget.name);
    }

    let wrapperClass = 'form-group';
    if (props.error && props.error.length > 0) {
        wrapperClass += ' has-error';
    }

    return (
        <div className={wrapperClass}>
            <div className="field">
                <select className="form-control" name={props.name} value={props.value} onChange={handleOnChange}>
                    {props.options.map(option => (
                        <option key={option.Value} value={option.Value}>
                            {option.Label}
                        </option>
                    ))}
                </select>
                {props.error && <div className="alert alert-danger"> {props.error} </div>}
            </div>
        </div>
    );
};
function areEqual(prevProps: Props, nextProps: Props): boolean {
    if (prevProps.value === nextProps.value && prevProps.options === nextProps.options && prevProps.error === nextProps.error) return true;
    else return false;
}
export default React.memo(SelectInput, areEqual);
