import axios, { AxiosInstance } from 'axios';
import config from '../config';
//import { Notifications } from '../modules/nextmove/utils';

export function getBaseApiUrl(): string {
    //const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    const baseUrl = config.apiGateway.URL;
    return baseUrl == null ? '/' : baseUrl + 'api/';
}

export function createAxiosApiConnection(): AxiosInstance {
    const instance = axios.create({
        baseURL: getBaseApiUrl(),
    });

    //instance.interceptors.response.use((response) => response, (error) => {
    //    if (error.response.status === 403) {
    //        Notifications.error("Wystąpił problem związany z uprawnieniami do danej akcji. Odśwież stronę, jeżeli problem będzie się powtarzał, skontaktuj się z administratorem.");
    //    }

    //    return Promise.reject(error);
    //})

    return instance;
}
