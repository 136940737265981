import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { IApplicationState, reducers } from './../reducers';

function buildRootReducer(allReducers: any) {
    return combineReducers<IApplicationState>(Object.assign({}, allReducers, { routing: routerReducer }));
}

export default function configureStore(history: any, initialState: any) {
    const windowDefine = window as any;

    const middleware = [thunk, routerMiddleware(history)];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof windowDefine !== 'undefined' && windowDefine.devToolsExtension) {
        enhancers.push(windowDefine.devToolsExtension());
    }

    return createStore(buildRootReducer(reducers), initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
