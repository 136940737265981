import React, { useEffect, useState } from 'react';
import { Col, Container, Row, ToastContainer } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import { toast } from 'react-toastify';
import { IMvpDataRow } from '../../api/zawody/model/GetMvpResponse';
import IGetMvpRequest from '../../api/zawody/model/GetMvpRequest';
import SelectInput from '../shared/controls/SelectInput';
import { Option } from './../shared/controls/models/common';
import { Link } from 'react-router-dom';

interface ImvpDisplay {
    zawodnik_id: number;
    nazwisko: string;
    imie: string;
    sumaMvp: number;
    zawody: ImvpDisplayZawody[];
}
interface ImvpDisplayZawody {
    zawody_id: number;
    suma: number;
    miejsce: string;
    punktyMvp: number;
    punktyUsed: boolean;
    isMp: string;
}

const MvpJunMl: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [wyniki, setWyniki] = useState<IMvpDataRow[]>([]);
    const [wynikiMvp, setWynikiMvp] = useState<ImvpDisplay[]>([]);
    const [data, setdata] = useState<IGetMvpRequest>({ plecId: '1', konkurencjaId: '4' });
    const [selectKonkurencje, setSelectKonkurencje] = useState<Option[]>([
        { Label: 'PPN', Value: '1', AditionalData: '' },
        { Label: 'PSZ', Value: '3', AditionalData: '' },
        { Label: 'PSP', Value: '4', AditionalData: '' },
    ]);

    const [selectPlec, setSelectPlec] = useState<Option[]>([
        { Label: 'Kobiet', Value: '1', AditionalData: '' },
        { Label: 'Mężczyzn', Value: '2', AditionalData: '' },
    ]);
    useEffect(() => {
        getWyniki();
    }, []);

    useEffect(() => {
        setWynikiMvp([]);
        getWyniki();
    }, [data]);

    useEffect(() => {
        const www = convertWynikiToDisplay();
        setWynikiMvp(www);
        // console.log(www);
        // console.log(wyniki);
    }, [wyniki]);

    const onSelectChange = (value: string, name: string) => {
        setdata(prevouData => ({
            ...prevouData,
            [name]: value,
        }));
    };

    const getWyniki = () => {
        setLoaderVisible(true);
        statystykiClient()
            .getPpnMvpJunMl(data)
            .then((result: IMvpDataRow[]) => {
                if (result == null) {
                    toast.error('Nie znaleziono zawodnika', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else setWyniki(result);

                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    const loaderStyles = () => {
        return {
            opacity: loaderVisible ? '0.6' : '0',
            height: loaderVisible ? '100%' : '0',
            zIndex: 999999999,
        };
    };

    const pointsForPlace = (place: number, isMp: boolean): number => {
        if (place == 1) return isMp ? 15 : 5;
        if (place == 2) return isMp ? 12 : 3;
        if (place == 3) return isMp ? 9 : 1;
        if (place == 4) return isMp ? 7 : 0;
        if (place == 5) return isMp ? 5 : 0;
        if (place == 6) return isMp ? 3 : 0;
        if (place == 7) return isMp ? 2 : 0;
        if (place == 8) return isMp ? 1 : 0;

        return 0;
    };

    const evalueMvp = (wynik: string, miejsce: string, isMp: string): number => {
        let punktyMiejsce = pointsForPlace(+miejsce, isMp == '1');
        let punktyKlasa = 0;
        let SecondClassValue = 450;
        if (data.konkurencjaId == '3') SecondClassValue = 520;
        if (data.konkurencjaId == '4') SecondClassValue = 545;

        if (+wynik >= SecondClassValue) {
            punktyKlasa += 10;
            punktyKlasa += +wynik - SecondClassValue;
        }
        if (+wynik < SecondClassValue) {
            let p1 = +wynik - SecondClassValue;
            let p2 = Math.abs(p1);
            if (p2 > 10) {
                p2 = 10;
            }
            punktyKlasa = 10 - p2;
        }
        let punkty = punktyMiejsce + punktyKlasa;
        return punkty < 0 ? 0 : punkty;
    };

    const convertWynikiToDisplay = (): ImvpDisplay[] => {
        const result: ImvpDisplay[] = [];
        wyniki.forEach(wynik => {
            const zawody = result.find(item => item.zawodnik_id === wynik.zawodnik_id);
            const punktyMvpZaZawody = evalueMvp(wynik.suma.toString(), wynik.miejsce, wynik.isMP);
            if (zawody) {
                const zawodyIndex = result.indexOf(zawody);
                //result[zawodyIndex].sumaMvp += punktyMvpZaZawody;
                result[zawodyIndex].zawody.push({
                    zawody_id: wynik.zawody_id,
                    suma: wynik.suma,
                    miejsce: wynik.miejsce,
                    punktyMvp: punktyMvpZaZawody,
                    punktyUsed: false,
                    isMp: wynik.isMP,
                });
            } else {
                result.push({
                    zawodnik_id: wynik.zawodnik_id,
                    nazwisko: wynik.nazwisko,
                    imie: wynik.imie,
                    sumaMvp: 0,
                    zawody: [
                        {
                            zawody_id: wynik.zawody_id,
                            suma: wynik.suma,
                            miejsce: wynik.miejsce,
                            punktyMvp: punktyMvpZaZawody,
                            punktyUsed: false,
                            isMp: wynik.isMP,
                        },
                    ],
                });
            }
        });
        //foreach result add 3 best punktyMvp
        result.forEach(item => {
            item.zawody.sort((a, b) => b.punktyMvp - a.punktyMvp);
            let sumaMvp = 0;
            for (let index = 0; index < item.zawody.length; index++) {
                sumaMvp += item.zawody[index]?.punktyMvp;
                item.zawody[index].punktyUsed = true;
                if (index == 2) {
                    break;
                }
            }
            item.sumaMvp = sumaMvp;
            //item.sumaMvp = item.zawody.reduce((a, b) => a + b.punktyMvp, 0);
        });
        return result.sort((a, b) => b.sumaMvp - a.sumaMvp);
    };
    const punktyUsedBGColor = (used: boolean) => {
        return used ? 'bg-punktyTaken' : '';
    };

    function zawodyLink(zawodyId: string) {
        return `/zawody/${zawodyId}/`;
    }
    function userLink(userId: string) {
        return `/zawodnik/${userId}/`;
    }
    const render1 = () => {
        //render wynikiMvp as table]
        const zawodyId = [...new Set(wyniki.map(item => item.zawody_id))];
        return (
            <table className="table align-middle text-center">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Zawodnik</th>
                        <th>Imie</th>
                        <th>SumaMvp</th>
                        {zawodyId.map(element => {
                            //find nazwa zawodow
                            const zawody = wyniki.find(item => item.zawody_id === element);
                            const zawodyIdString = zawody?.zawody_id.toString() ?? '';
                            return (
                                <th key={element} className="zawodyCol">
                                    <Link to={zawodyLink(zawodyIdString)}>{zawody?.nazwa}</Link>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {wynikiMvp.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <Link to={userLink(item.zawodnik_id.toString())}>{item.nazwisko}</Link>
                                </td>
                                <td>{item.imie}</td>
                                <td>{item.sumaMvp}</td>
                                {zawodyId.map(element => {
                                    const zawody = item.zawody.find(item => item.zawody_id === element);
                                    if (zawody) {
                                        return (
                                            <td key={index}>
                                                <div>
                                                    {zawody?.miejsce} miejsce / {zawody?.suma}pkt
                                                </div>
                                                <div className={punktyUsedBGColor(zawody.punktyUsed)}>{zawody?.punktyMvp}pkt MVP </div>
                                            </td>
                                        );
                                    } else
                                        return (
                                            <td>
                                                <div>-</div>
                                                <div>-</div>
                                            </td>
                                        );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    return (
        <>
            <div className="busy_loader" style={loaderStyles()}>
                <Loader type="Grid" visible={loaderVisible} />
            </div>
            <Container className="searchBlock">
                <Row>
                    <Col className="d-flex justify-content-center">
                        <h3>MVP Junior Młodszy</h3>
                    </Col>
                </Row>
            </Container>
            <Container className="Panel">
                <Row>
                    <Col>
                        <fieldset className="listAdder">
                            <legend className="listAdder">Filtruj</legend>
                            {/* <Row>
                                <Col className="blue">Rok</Col>
                                <Col className="light-blue">
                                    <SelectInput name="rok" value={sredniaData.rok} onChange={onSelectChange} error={errors['rok']} options={selectRok} />
                                </Col>
                            </Row> */}
                            <Row>
                                <Col className="blue">Konkurencja</Col>
                                <Col className="light-blue">
                                    <SelectInput name="konkurencjaId" value={data.konkurencjaId} onChange={onSelectChange} options={selectKonkurencje} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Płeć</Col>
                                <Col className="light-blue">
                                    <SelectInput name="plecId" value={data.plecId} onChange={onSelectChange} options={selectPlec} />
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>

                {render1()}
            </Container>
            <ToastContainer />
        </>
    );
};

export default MvpJunMl;
