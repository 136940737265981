import { Col, Container, Row, Table } from 'react-bootstrap';
import React from 'react';
import IGetBestOfTheYear from '../../api/zawody/model/GetBestOfTheYearResult';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

export const zawodyLink = (zawodyId: string) => {
    return `/zawody/${zawodyId}/`;
};
export const userLink = (userId: string) => {
    return `/zawodnik/${userId}/`;
};
export const klubLink = (klubId: string) => {
    return `/klub/${klubId}/`;
};
export const renderNaglowek = (naglowek: string) => {
    return (
        <Container className="naglowekBlock">
            <Row>
                <Col className="d-flex justify-content-center">
                    <h3>{naglowek}</h3>
                </Col>
            </Row>
        </Container>
    );
};
export const renderLista = (wynikiData: IGetBestOfTheYear[] | undefined) => {
    if (typeof wynikiData !== 'undefined') {
        const group1 = wynikiData.reduce((r: any, a: IGetBestOfTheYear) => {
            r[a.konkurencja] = [...(r[a.konkurencja] || []), a];
            return r;
        }, []);

        return Object.keys(group1).map(key => {
            return (
                <Col key={key}>
                    <div className="konkurencjaDiv">{key}</div>
                    <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                        <thead>
                            <tr>
                                <th>Wynik</th>
                                <th>Nazwisko</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {group1[key].map((item: IGetBestOfTheYear, index: any) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center">{item.suma}</td>

                                        <td className="text-center">
                                            <Link to={userLink(item.zawodnik_id)}>{item.nazwisko}</Link>
                                        </td>

                                        <td className="text-center">
                                            <Link to={zawodyLink(item.zawody_id)}>{dateFormat(item.data, 'dd-mm-yyyy')}</Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            );
        });
    }
};
