import React, { ChangeEventHandler, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextInput from './../shared/controls/TextInput';
import SelectInput from './../shared/controls/SelectInput';
import { Option } from './../shared/controls/models/common';
import DatePicker from './../shared/controls/DatePicker';
import { Link, match } from 'react-router-dom';
import { IZawodyModel } from '../../api/zawody/model/ZawodyModel';
import Loader from 'react-loader-spinner';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import IGetZawodyResponse from '../../api/zawody/model/ZawodyResponse';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import ISaveZawodyResponse from '../../api/zawody/model/SaveZawodyResponse';
import { IWynikSaveModel } from '../../api/zawody/model/WynikSaveModel';
import ISaveWynikiResponse from '../../api/zawody/model/SaveWynikResponse';
import IGetWynikiResponse from '../../api/zawody/model/GetWynikiResponse';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

interface IDetailParams {
    id: string;
}

interface IDetailsProps {
    match?: match<IDetailParams>;
}

const Zawody: React.FC<IDetailsProps> = ({ match }: IDetailsProps) => {
    const history = useHistory();

    const [loaderVisible, setLoaderVisible] = useState(false);

    const [zawodyData, setZawodyData] = useState<IZawodyModel>({
        nazwa: '',
        miejsce: '',
        data: '',
        ranga: '',
        id: '',
        wyniki: [],
        komunikat: '',
    });

    const [wynikSaveData, setWynikiSaveData] = useState<IWynikSaveModel>({
        konkurencjaId: '1',
        csv: '',
        zawodyId: '',
        kategoriaWiekowaId: '1',
        plecId: '1',
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [wynikiData, setwynikiData] = useState<IGetWynikiResponse[]>();

    const [selectRanga, setSelectRanga] = useState<Option[]>([
        { Label: 'Krajowe', Value: '1', AditionalData: '' },
        { Label: 'Międzynarodowe', Value: '2', AditionalData: '' },
    ]);
    const [selectKonkurencje, setSelectKonkurencje] = useState<Option[]>([
        { Label: 'PPN', Value: '1', AditionalData: '' },
        { Label: 'PDW', Value: '2', AditionalData: '' },
        { Label: 'PSZ', Value: '3', AditionalData: '' },
        { Label: 'PSP', Value: '4', AditionalData: '' },
        { Label: 'PST', Value: '5', AditionalData: '' },
        { Label: 'PCZ', Value: '6', AditionalData: '' },
        { Label: 'PPN MIX', Value: '7', AditionalData: '' },
    ]);
    const [selectKategorieWiekowe, setSelectKategorieWiekowe] = useState<Option[]>([
        { Label: 'Senior/Seniorka', Value: '1', AditionalData: '' },
        { Label: 'Junior/Juniorka', Value: '2', AditionalData: '' },
        { Label: 'Junior/Juniorka mł.', Value: '3', AditionalData: '' },
        { Label: 'Młodzik/Młodziczka', Value: '4', AditionalData: '' },
    ]);
    const [selectPlec, setSelectPlec] = useState<Option[]>([
        { Label: 'Kobiet', Value: '1', AditionalData: '' },
        { Label: 'Mężczyzn', Value: '2', AditionalData: '' },
        { Label: 'Open', Value: '3', AditionalData: '' },
    ]);

    const onchange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value, checked, type } = e.target;
        if (name === 'konkurencjaId' || name === 'csv')
            setWynikiSaveData(prevouData => ({
                ...prevouData,
                [name]: value,
            }));
        else
            setZawodyData(prevouData => ({
                ...prevouData,
                [name]: type === 'checkbox' ? checked : value,
            }));
    };

    const onSelectChange = (value: string, name: string) => {
        if (name === 'konkurencjaId' || name === 'csv' || name === 'kategoriaWiekowaId' || name === 'plecId')
            setWynikiSaveData(prevouData => ({
                ...prevouData,
                [name]: value,
            }));
        else
            setZawodyData(prevouData => ({
                ...prevouData,
                [name]: value,
            }));
    };

    const getWyniki = () => {
        const id = match?.params.id === undefined ? 0 : Number(match?.params.id);
        setLoaderVisible(true);
        statystykiClient()
            .getWynikiByZawody(id)
            .then((result: IGetWynikiResponse[]) => {
                setwynikiData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        setLoaderVisible(true);
        const id = match?.params.id === undefined ? 0 : Number(match?.params.id);
        getWyniki();
        statystykiClient()
            .getZawody(id)
            .then((result: IZawodyModel) => {
                if (result == null) {
                    toast.error('Nie znaleziono zawodów', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else setZawodyData(result);

                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    }, []);

    const makeSave = () => {
        setLoaderVisible(true);
        if (zawodyData.id != '') {
            const updateModel: object = {
                id: zawodyData.id,
                nazwa: zawodyData.nazwa,
                miejsce: zawodyData.miejsce,
                data: zawodyData.data,
                ranga: Number(zawodyData.ranga),
                komunikat: zawodyData.komunikat,
            };
            statystykiClient()
                .updateZawody(updateModel)
                .then((result: IZawodyModel) => {
                    if (result.id != 'undefined') {
                        toast.success('Zapisano zawody.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        zawodyData.id = result.id;
                    } else {
                        toast.error('Błąd zapisywania zawodów.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    setLoaderVisible(false);
                })
                .catch(function(error) {
                    toast.error('Nie znaleziono zawodów', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoaderVisible(false);
                });
        } else
            statystykiClient()
                .createZawody(zawodyData)
                .then((result: IZawodyModel) => {
                    if (result.id != 'undefined') {
                        toast.success('Zapisano zawody.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        zawodyData.id = result.id;

                        history.push('/zawodyDetale/' + result.id);
                    } else {
                        toast.error('Błąd zapisywania zawodów.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    setLoaderVisible(false);
                })
                .catch(function(error) {
                    toast.error('Nie znaleziono zawodów', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoaderVisible(false);
                });
    };

    const saveCsv = () => {
        const csvSaveModel: IWynikSaveModel = {
            zawodyId: zawodyData.id,
            csv: wynikSaveData.csv,
            konkurencjaId: wynikSaveData.konkurencjaId,
            kategoriaWiekowaId: wynikSaveData.kategoriaWiekowaId,
            plecId: wynikSaveData.plecId,
        };
        setLoaderVisible(true);
        statystykiClient()
            .saveCsv(csvSaveModel)
            .then((result: ISaveWynikiResponse) => {
                if (result.saveResult === true) {
                    toast.success('Zapisano wyniki.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    //zawodyData.id = result.zawodyId;
                } else {
                    toast.error('Błąd zapisywania wyników.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                setLoaderVisible(false);
                getWyniki();
            })
            .catch(function(error) {
                toast.error('błąd', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoaderVisible(false);
            });
    };

    const loaderStyles = () => {
        return {
            opacity: loaderVisible ? '0.6' : '0',
            height: loaderVisible ? '100%' : '0',
            zIndex: 999999999,
        };
    };

    function userLink(userId: string) {
        return `/zawodnik/${userId}/`;
    }

    const renderLista = () => {
        if (typeof wynikiData !== 'undefined') {
            console.log(wynikiData);
            let group1 = wynikiData.reduce((r: any, a: IGetWynikiResponse) => {
                r[a.konkurencja.nazwa + ' ' + a.kategoriaWiekowa.nazwa + ' ' + a.plec.nazwa] = [...(r[a.konkurencja.nazwa + ' ' + a.kategoriaWiekowa.nazwa + ' ' + a.plec.nazwa] || []), a];
                return r;
            }, []);
            console.log(group1);
            return Object.keys(group1).map(key => {
                return (
                    <div key={key}>
                        <div className="konkurencjaDiv">{key}</div>
                        <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                            <thead>
                                <tr>
                                    <th className="zawodyWynikiTableMiejsce">M-ce</th>
                                    <th className="zawodyWynikiTableNazwisko">Nazwisko</th>
                                    <th className="zawodyWynikiTableImie">Imie</th>
                                    <th className="zawodyWynikiTableImie">Klub</th>
                                    <th className="zawodyWynikiTableseria">1</th>
                                    <th className="zawodyWynikiTableseria">2</th>
                                    <th className="zawodyWynikiTableseria">3</th>
                                    <th className="zawodyWynikiTableseria">4</th>
                                    <th className="zawodyWynikiTableseria">5</th>
                                    <th className="zawodyWynikiTableseria">6</th>
                                    <th className="zawodyWynikiTableseria">Suma</th>
                                    <th className="zawodyWynikiTableseria">10x</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group1[key].map((item: IGetWynikiResponse, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{item.miejsce}</td>
                                            <td>
                                                <Link to={userLink(item.zawodnik.id)}>{item.zawodnik.nazwisko}</Link>
                                            </td>
                                            <td>{item.zawodnik.imie}</td>
                                            <td>{item.zawodnik.klub}</td>
                                            <td className="text-center">{item.seria1}</td>
                                            <td className="text-center">{item.seria2}</td>
                                            <td className="text-center">{item.seria3}</td>
                                            <td className="text-center">{item.seria4}</td>
                                            <td className="text-center">{item.seria5}</td>
                                            <td className="text-center">{item.seria6}</td>
                                            <td className="text-center">{+item.seria1 + +item.seria2 + +item.seria3 + +item.seria4 + +item.seria5 + +item.seria6}</td>
                                            <td className="text-center">{item.centralne}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                );
            });
        }
    };

    const ZawodyEnabled = zawodyData.id !== '';

    return (
        <>
            <div className="busy_loader" style={loaderStyles()}>
                <Loader type="Grid" visible={loaderVisible} />
            </div>
            <Container className="Panel">
                <Row>
                    <Col>
                        <fieldset className="listAdder">
                            <legend className="listAdder">Zawody</legend>
                            <Row>
                                <Col>AJDI: {zawodyData.id}</Col>
                            </Row>
                            <Row>
                                <Col className="blue">Nazwa Zawodów</Col>
                                <Col className="light-blue">
                                    <TextInput name="nazwa" value={zawodyData.nazwa} onChange={onchange} error={errors['nazwa']} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Miejsce</Col>
                                <Col className="light-blue">
                                    <TextInput name="miejsce" value={zawodyData.miejsce} onChange={onchange} error={errors['miejsce']} />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="blue">Ranga</Col>
                                <Col className="light-blue">
                                    <SelectInput name="ranga" value={zawodyData.ranga} onChange={onSelectChange} error={errors['ranga']} options={selectRanga} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Data Zawodów</Col>
                                <Col className="light-blue">
                                    <DatePicker name="data" value={zawodyData.data} onChange={onSelectChange} error={errors['data']} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Komunikat</Col>
                                <Col className="light-blue">
                                    <TextInput name="komunikat" value={zawodyData.komunikat} onChange={onchange} error={errors['komunikat']} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="TabButtons">
                                    <button type="button" className="btn btn-outline-secondary btn-green mb-10">
                                        Wróć
                                    </button>
                                    <button type="button" onClick={makeSave} className="btn btn-outline-secondary btn-green ml-10 mb-10">
                                        Zapisz
                                    </button>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>
            </Container>
            {ZawodyEnabled ? (
                <Container>
                    <Row>
                        <Col>
                            <fieldset className="listAdder">
                                <legend className="listAdder">Dodaj Wyniki</legend>
                                <Row>
                                    <Col>Wklej wyniki w formacie CSV " miejsce;nazwisko;imie;rocznik;klub;seria1;2;3;4;5;6;suma;centralne;kraj| " </Col>
                                </Row>
                                <Row>
                                    <Col className="blue">Konkurencja</Col>
                                    <Col className="light-blue">
                                        <SelectInput name="konkurencjaId" value={wynikSaveData.konkurencjaId} onChange={onSelectChange} error={errors['konkurencjaId']} options={selectKonkurencje} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="blue">Rodzaj</Col>
                                    <Col className="light-blue">
                                        <SelectInput name="plecId" value={wynikSaveData.plecId} onChange={onSelectChange} error={errors['plecId']} options={selectPlec} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="blue">Kategoria wiekowa</Col>
                                    <Col className="light-blue">
                                        <SelectInput name="kategoriaWiekowaId" value={wynikSaveData.kategoriaWiekowaId} onChange={onSelectChange} error={errors['kategoriaWiekowaId']} options={selectKategorieWiekowe} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="blue">Text CSV</Col>
                                    <Col className="light-blue">
                                        <TextInput name="csv" value={wynikSaveData.csv} onChange={onchange} error={errors['csv']} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="TabButtons">
                                        <button type="button" onClick={saveCsv} className="btn btn-outline-secondary btn-green ml-10 mb-10">
                                            Dodaj wyniki
                                        </button>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <fieldset className="listAdder">
                                <legend className="listAdder">Wyniki</legend>
                                {renderLista()}
                            </fieldset>
                        </Col>
                    </Row>
                </Container>
            ) : null}
            <ToastContainer />
        </>
    );
};

export default Zawody;
